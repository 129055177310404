export function AccessibilidadeIcon() {
    return (
	
		<svg 
			xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 982.34 1402.92"
		>
			<path d="M350.04 490.95c0,634.75 -74.89,560.06 490.05,560.06l0 350.04 140.02 0c0,-559.87 52.19,-525.06 -350.04,-525.06l0 -227.53c72.9,19.46 135.42,122.51 350.04,122.51l0 -140.02c-127.84,-29.78 -181.67,-33.53 -267.43,-117.62 -57.04,-55.93 -125.31,-162.42 -222.63,-162.42 -84.66,0 -140.02,55.36 -140.02,140.02zm0 -350.04c0,185.07 280.03,191.2 280.03,0 0,-187.87 -280.03,-187.87 -280.03,0zm-350.04 892.61c0,464.96 606.91,487.45 700.08,87.51l-157.52 0c-189.35,357.86 -620.02,-73.38 -262.53,-262.53l0 -157.52c-91.34,43.82 -280.04,96.46 -280.04,332.54z"/>
		</svg>

    )
}