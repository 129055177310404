import { useEffect, useState } from "react";
import { useApp } from "../../context/app";
import FlatList from 'flatlist-react';
import { Header } from "../../components/Header";
import { HeaderMobile } from "../../components/HeaderMobile";
import { api, urls } from "../../services/api";
import { ICategorieNews, INews, IPaginacaoNews } from "../../interfaces/news";
import { TitlePage } from "../../components/TitlePage";
import Icon from '@mdi/react';
import { mdiImageOff } from '@mdi/js';
import { ItemCategorie } from "./components/itemCategorie";
import { SearchMobile } from "../../components/SearchMobile";
import { ServicesAccordion } from "../../components/ServicesAccordion";
import { Banner } from "../../components/Banner";
import { Videos } from "../../components/Videos";
import { MapSite } from "../../components/MapSite";
import { Footer } from "../../components/Footer";
import VLibras from '@djpfs/react-vlibras';
import brasao from '../../assets/imgs/brasao.png'
import { FilterIcon } from "../../components/icons/common/filter"
import { Fechar } from "../../components/icons/common/fechar"
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

//https://mui.com/material-ui/material-icons/
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export function ListNewsScreen() {

	const [news, setNews] = useState<INews[]>([])
	const [categories, setCategories] = useState<ICategorieNews[]>([])
	const [layoutGrid, setLayoutGrid] = useState<boolean>(false)
	const [filterMenu, setFilterMenu] = useState<boolean>(false)
	const [flag, setFlag] = useState<boolean>(false);
	const toggleFlag = () => setFlag(value => !value);
	const [idCategorie, setIdCategorie] = useState<number>(99)
	const { mobile, menuMobile, visible, configDomainURL } = useApp()
	const domain = configDomainURL
	const [paginacao, setPag] = useState<IPaginacaoNews>()
	const [pag_atual, setPagAtual] = useState<number>(1)
	const [total_paginas, setPagFinal] = useState<number>(0)
	const [filter, setFilter] = useState<boolean>(false)

	const [loading, setLoading] = useState<string>('Carregando...')

	function parseDate(date: string) {

		const dateSplit = date.split('/')

		//console.log('dateSplit - ln 30:')
		//console.log(dateSplit)

		const monthsOfYear = [
			'Janeiro',
			'Fevereiro',
			'Março',
			'Abril',
			'Maio',
			'Junho',
			'Julho',
			'Agosto',
			'Setembro',
			'Outubro',
			'Novembro',
			'Dezembro'
		]

		const year = dateSplit[2].split(' -')[0]
		const month = monthsOfYear[parseInt(dateSplit[1]) - 1]
		const dateParsed = `${dateSplit[0]} ${month} ${year}`

		return dateParsed

	}

	const handleGetNewsByCategorie = async (id: number) => {

		try {

			if (id == 99) {

				const response = await api.get(urls.ListNews)
				setNews(response.data.noticias)
				setIdCategorie(id)

				return

			}

			const response = await api.get(urls.NewsByCategorie + id)

			setNews(response.data.noticias)
			setIdCategorie(id)

			if (response.data.noticias.length < 1) { setLoading('Sem notícias relacionadas.') }

		}
		catch (error) {
			console.log('ListNews - handleGetNewsByCategorie - Erro: ', error)
		}

	}

	const calc_prox_pag = (result: any) => {

		try {

			let item = result + 1

			return item

		}
		catch (error) {
			console.log('ListNews - somar_prox_pag - Erro: ', error)
		}

	}

	const calc_pag_anterior = (result: any) => {

		try {

			let item = result - 1

			return item

		}
		catch (error) {
			console.log('ListNews - calc_pag_anterior - Erro: ', error)
		}

	}

	const getPaginacao = async (id: any) => {

		try {

			//console.log('id', id)

			var response = await api.get(urls.NewsByPage + id)
			//console.log('response.data.noticias', response.data.noticias)

			//setNews(response.data.noticias)
			
			for( let i = 0; i < response.data.noticias.length; i++ ){ 
	
				//console.log( response.data.noticias[i] );
				
				setNews(news => [...news, response.data.noticias[i] ] )
				
			}
			
			setPagAtual(id)
			
		}
		catch (error) {
			console.log('Paginacao - getPaginacao - Erro: ', error)
		}
		
	}
	
	const ListNewsScreen_img_caminho = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/foto/'

	useEffect(() => { //LISTAR NOTÍCIAS

		(async () => {

			try {

				const response = await api.get(urls.ListNews)
				//console.log('response.data', response.data)
				setNews(response.data.noticias)

			}
			catch (error) {
				console.log('ListNews ln 115 - Erro: ', error)
			}

		})()
		
	}, [])

	useEffect(() => { //LISTAR CATEGORIAS

		(async () => {

			try {

				const response = await api.get<ICategorieNews[]>(urls.ListCategories)
				//console.log('ListNews ln 129 - response.data: ', response.data)
				setCategories(response.data)

			}
			catch (error) {
				console.log('ListNews ln 134 - Erro: ', error)
			}

		})()

	}, [])

	useEffect(() => { //PAGINAÇÃO

		(async () => {

			try {

				const response = await api.get(urls.ListNews)
				setPag(response.data.paginacao)
				setPagFinal(response.data.paginacao.total_paginas)

			}
			catch (error) {
				console.log('ListNews - Erro: ', error)
			}

		})()

	}, [])

	useEffect(() => { //FILTROS

		setFilter(filter)
		
	}, [filter])
	
	//console.log('news', news)
	
	return (
		<div className="pages-ListNews">

			<>

				{

					menuMobile ?

						(

							<HeaderMobile />

						)

						:

						(

							<Header
								search
								route={` Notícias`}
							/>

						)

				}

				{

					mobile &&

					<SearchMobile />

				}

				{

					mobile &&

					<div className="pages-Entity-mobile-separador" />

				}

				<div className="pages-ListNews-filtro-campo">

					<TitlePage
						title="NOTÍCIAS"
						iconNews
						onChange={(value) => {
							setLayoutGrid(value)
							setFilterMenu(value)

						}}
					/>

					<div
						className="pages-ListNews-filtro-btn"
						onClick={() => setFilter(!filter)}
					><FilterIcon /></div>

				</div>

				<div
					className={`pages-ListNews-escurecer ${filter === false ? "" : "on"}`}
					onClick={() => setFilter(!filter)}
				>
					<div className="pages-ListNews-escurecer-fechar"><Fechar /></div>
				</div>

				<div className="pages-ListNews-container">

					<div className={`pages-ListNews-container-coluna-esq ${filter === false ? "" : "on"}`}>

						<ItemCategorie
							idActual={idCategorie}
							onClick={() => handleGetNewsByCategorie(99)}
							newsCategorie={{
								newsCategoryId: 99,
								title: 'Geral'
							}}
						/>

						<FlatList
							list={categories}
							renderItem={(item) => (

								<ItemCategorie
									key={item.newsCategoryId}
									idActual={idCategorie}
									newsCategorie={item}
									onClick={() => handleGetNewsByCategorie(item.newsCategoryId)}
								/>

							)}
						/>

					</div>

					{

						news.length < 1 ?

							(

								<div className="pages-ListNews-empty">{loading}</div>

							)

							:

							layoutGrid ?

								(

									<div className="pages-ListNews-container-coluna-dir">

										<div className="pages-ListNews-card-container">

											<FlatList
												list={news}
												renderItem={(item) => (

													<a key={item.newsId} href={`${domain}noticias/${item.newsId}/${item.title.toLowerCase().replaceAll(' ', '-').replaceAll('/', '-').replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, "")}`}>

														<div className="pages-ListNews-card-box">

															{
																item.img ?

																	<img src={ListNewsScreen_img_caminho + item.img} />

																	:

																	<div
																		className="pages-ListNews-card-img empty"
																		style={{
																			backgroundImage: 'url( ' + brasao + ' )',
																		}}
																	></div>

															}

															<div className="pages-ListNews-campo">
																<div className="pages-ListNews-titulo">{item.title}</div>
																<div className="pages-ListNews-data">{parseDate(item.published)}</div>
															</div>

														</div>

													</a>

												)}

											/>

										</div>

									</div>

								)

								:

								(

									<div className="pages-ListNews-container-coluna-dir layoutGrid">

										<FlatList
											list={news}
											renderItem={(item) => (

												<a key={item.newsId} href={`${domain}noticias/${item.newsId}/${item.title.toLowerCase().replaceAll(' ', '-').replaceAll('/', '-').replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, "")}`}>

													<div className="pages-ListNews-layoutGrid-box">

														{

															item.img ?

															<img src={ListNewsScreen_img_caminho + item.img} />

															:

															<div
																className="pages-ListNews-card-img empty"
																style={{
																	backgroundImage: 'url( ' + brasao + ' )',
																}}
															></div>

														}

														<div className="pages-ListNews-layoutGrid-campo">
															<div className="pages-ListNews-layoutGrid-titulo">{item.title}</div>
															<div className="pages-ListNews-layoutGrid-resumo">{item.description}</div>
															<div className="pages-ListNews-layoutGrid-data">{parseDate(item.published)}</div>
														</div>

													</div>

													<div className="pages-ListNews-layoutGrid-bar" />

												</a>

											)}

										/>

									</div>

								)

					}

				</div>

				<div className="pages-ListNews-paginacao">

					<div className="pages-ListNews-paginacao-container">

						<div
							className="pages-ListNews-btn atual"
							id="sentinela"
							onClick={() => getPaginacao(pag_atual + 1)}
						>Carregar mais notícias</div>
						
					</div>

				</div>

				<Banner mobile={mobile} />

				<Videos mobile={mobile} />

				<MapSite />

				<Footer />

				{

					visible &&

					(

						<VLibras forceOnload />

					)

				}

			</>

		</div>

	)

}