import React from "react"
import ReactDOM from 'react-dom'
import { useNavigate } from "react-router-dom"
import { FacebookIcon } from "../icons/social/facebook"
import { InstagramIcon } from "../icons/social/instagram"
import { useEffect, useState } from "react";
import { YoutubeIcon } from "../icons/social/youtube"
import { FlickrIcon } from "../icons/social/flickr"
import { useApp } from "../../context/app"
import { PhoneIcon } from "../icons/common/phone"
import { SinaisIcon } from "../icons/acessibilidade/sinais"
import { ContrasteIcon } from "../icons/acessibilidade/contraste"
import { FonteAumentar } from "../icons/acessibilidade/FonteAumentar"
import { FonteReset } from "../icons/acessibilidade/FonteReset"
import { FonteDiminuir } from "../icons/acessibilidade/FonteDiminuir"
import { AccessibilidadeIcon } from "../icons/acessibilidade/accessibilidade"
import VLibras from "@djpfs/react-vlibras";
import './style.css';

export function ToolBar() {
	
	const navigate = useNavigate()
	
	const { mobile, setVisible, visible } = useApp()
	const [tema_escuro, setTema] = useState<number>(0)
	
	/*Start - FONT RESIZE*/
	const [esconderFonteReset, setEsconderFonteReset] = useState<boolean>(false)
	const pagina_atual = window.location.pathname
	const pagina_atual_Array = pagina_atual.split('/');
	
	const fontResize = [
		'body',
		'.ToolBar-botoes',
		'.ToolBar-contato-btn',
		'.Header-navBarHover',
		'.Footer-endereco',
	]
	
	const boxResize = [
		'.ToolBar-container',
		'.Header-itens',
		'.MapSite-container',
		'.Footer-container',
	]
	
	const multiFontResize = [
		'.hover-submenu-item-itemMenuNav',
		'.MapSite-TitleInfo',
		'.MapSite-txt',
		'.Footer-col',
	]
	if( pagina_atual == '/' ){ //HOME
		
		fontResize.push(
			'.hover-submenu-box',
			'.MaisNoticias-titulo',
			'.AcessoRapido-titulo',
			'.News-btn a',
			'.Search-form input',
			'.item-container',
			'.Services-bottom',
		)
		
		boxResize.push(
			'.Search-container',
			'.MaisNoticias-container',
			'.ListBottomNews',
			'.News-btn',
			'.AcessoRapido-titulo-container',
			'.AcessoRapido-container',
			'.Services-container',
		)
		
		multiFontResize.push(
			'.BottomNews-titulo',
			'.BottomNews-data',
			'.AcessoRapido-card-titulo',
			'.AcessoRapido-card-txt',
			'.Services-btn',
		)
		
	}
	
	if( //NOTICIA
		pagina_atual_Array[1] == 'noticias' 
		&& !!pagina_atual_Array[2]
	){
	
		fontResize.push(
			'.TitlePage-iconNews-titulo',
			'.pages-News-subtitle',
			'.pages-News-data',
			'.pages-News-conteudo',
			'.Header-search-form-input',
		)
		
		boxResize.push(
			'.TitlePage',
			'.pages-News-container',
		)
		
	}
	
	if( //listNews
		pagina_atual_Array[1] == 'noticias' 
		&& !pagina_atual_Array[2]
	){
	
		fontResize.push(
			'.Header-breadcrumb-item',
			'.TitlePage-iconNews-titulo',
			'.Header-search-form-input',
		)
		
		boxResize.push(
			'.TitlePage',
			'.pages-ListNews-container',
		)
		
		multiFontResize.push(
			'.page-ListNews-ItemCategorie',
			'.pages-ListNews-titulo',
			'.pages-ListNews-data',
			'.pages-ListNews-layoutGrid-titulo',
			'.pages-ListNews-layoutGrid-resumo',
			'.pages-ListNews-layoutGrid-data',
		)
		
	}
	
	if( //ENTIDADES
		pagina_atual_Array[1] == 'a' 
		&& !!pagina_atual_Array[2]
	){
	
		fontResize.push(
			'.TitlePage-iconNews-titulo',
			'.Header-breadcrumb-item',
			'.Header-search-form-input',
		)
		
		boxResize.push(
			'.TitlePage',
			'.pages-Entity-container',
		)
		
		multiFontResize.push(
			'.pages-Entity-itemCategorie',
			'.SubTitlePage-titulo',
			'.pages-Entity-linha-txt',
		)
		
	}
	
	if( //ARTIGOS
		pagina_atual_Array[1] == 'e' 
		&& !!pagina_atual_Array[2]
	){
	
		fontResize.push(
			'.TitlePage-iconNews-titulo',
			'.Header-breadcrumb-item',
			'.Header-search-form-input',
		)
		
		boxResize.push(
			'.TitlePage',
			'.pages-Article-container',
		)
		
		multiFontResize.push(
			'.pages-Article-conteudo h3',
		)
		
	}
	
	if( //LOCALIZAÇÃO
		pagina_atual_Array[1] == 'localizacao' 
		&& !pagina_atual_Array[2]
	){
	
		fontResize.push(
			'.Header-breadcrumb-item',
			'.Header-search-form-input',
		)
		
		boxResize.push(
			'.TitlePage',
			'.pages-Location-container',
		)
		
		multiFontResize.push(
			'.pages-Location-titulo',
			'.pages-Location-txt',
		)
		
	}
	
	if( //TELEFONES
		pagina_atual_Array[1] == 'telefones' 
		&& !pagina_atual_Array[2]
	){
	
		fontResize.push(
			'.Header-breadcrumb-item',
			'.pages-Contact-destaque-titulo',
			'.pages-Contact-destaque-telefone',
			'.pages-Contact-Title',
			'.Header-search-form-input',
		)
		
		boxResize.push(
			'.TitlePage',
			'.pages-Contact-container',
		)
		
		multiFontResize.push(
			'.pages-Contact-Item',
		)
		
	}
	
	//console.log( 'pagina_atual', pagina_atual );
	//console.log( 'pagina_atual_Array', pagina_atual_Array );
	//console.log( 'fontResize', fontResize );
	//console.log( 'boxResize', boxResize );
	//console.log( 'multiFontResize', multiFontResize );

	const resetFonte = () => {
		
		if( fontResize ){
		
			fontResize.forEach( alvo => {
			
				let el = document.querySelector( alvo )!
				
				setEsconderFonteReset(false)
				
				el.removeAttribute('style')
				
			})
			
		}
		
		if( boxResize ){

			boxResize.forEach( alvo02 => {
				
				let el02 = document.querySelector( alvo02 )!
				let boxResult = 'width:90vw;'
				
				el02.removeAttribute('style')
				
			})
			
		}
		
		if( multiFontResize ){
		
			multiFontResize.forEach( alvo03 => {
			
				let el03 = document.querySelectorAll( alvo03 )!
				
				el03.forEach( alvo03_item => {
					
					alvo03_item.removeAttribute('style')
					
				})
				
			})
			
		}
		
	}
	
	const aumentarFonte = () => {
		
		if( fontResize ){
		
			fontResize.forEach( alvo => {
			
				let el = document.querySelector( alvo )!
				
				let fonSizeFull = window.getComputedStyle( el, null ).getPropertyValue("font-size")
				let fonSizeStr = fonSizeFull.replace('px', '')
				let letFonSize = parseFloat( fonSizeStr )
				
				let lineHeightFull = window.getComputedStyle( el, null ).getPropertyValue("line-height")
				let lineHeightStr = lineHeightFull.replace('px', '')
				let letLineHeight = parseFloat( lineHeightStr )
				
				let FonSizeResult = 'font-size:'+ ( letFonSize + 5 ) +'px; line-height:'+ ( letLineHeight + 5 ) +'px;'
				
				setEsconderFonteReset(true)
				
				el.setAttribute('style', FonSizeResult)
				
			})
			
		}
		
		if( boxResize ){
		
			boxResize.forEach( alvo02 => {
				
				let el02 = document.querySelector( alvo02 )!
				let boxResult = 'width:90vw;'
				
				el02.setAttribute('style', boxResult)
				
			})
			
		}
		
		if( multiFontResize ){
		
			multiFontResize.forEach( alvo03 => {
			
				let el03 = document.querySelectorAll( alvo03 )!
				
				el03.forEach( alvo03_item => {
					
					//console.log( 'alvo03_item', alvo03_item )
					
					let fonSizeFull_multi = window.getComputedStyle( alvo03_item, null ).getPropertyValue("font-size")
					let fonSizeStr_multi = fonSizeFull_multi.replace('px', '')
					let letFonSize_multi = parseFloat( fonSizeStr_multi )
					
					let lineHeightFull_multi = window.getComputedStyle( alvo03_item, null ).getPropertyValue("line-height")
					let lineHeightStr_multi = lineHeightFull_multi.replace('px', '')
					let letLineHeight_multi = parseFloat( lineHeightStr_multi )
					
					let FonSizeResult_multi = 'font-size:'+ ( letFonSize_multi + 5 ) +'px; line-height:'+ ( letLineHeight_multi + 5 ) +'px;'
					
					alvo03_item.setAttribute('style', FonSizeResult_multi)
					
				})
				
			})
			
		}
		
	}
	
	const diminuirFonte = () => {
		
		if( fontResize ){
		
			fontResize.forEach( alvo => {
			
				let el = document.querySelector( alvo )!
				
				let fonSizeFull = window.getComputedStyle( el, null ).getPropertyValue("font-size")
				let fonSizeStr = fonSizeFull.replace('px', '')
				let letFonSize = parseFloat( fonSizeStr )
				
				let lineHeightFull = window.getComputedStyle( el, null ).getPropertyValue("line-height")
				let lineHeightStr = lineHeightFull.replace('px', '')
				let letLineHeight = parseFloat( lineHeightStr )
				
				let FonSizeResult = 'font-size:'+ ( letFonSize - 5 ) +'px; line-height:'+ ( letLineHeight - 5 ) +'px;'
				
				setEsconderFonteReset(true)
				
				el.setAttribute('style', FonSizeResult)
				
			})
			
		}
		
		if( boxResize ){
		
			boxResize.forEach( alvo02 => {
				
				let el02 = document.querySelector( alvo02 )!
				let boxResult = 'width:90vw;'
				
				el02.removeAttribute('style')
				
			})
			
		}
		
		if( multiFontResize ){
		
			multiFontResize.forEach( alvo03 => {
			
				let el03 = document.querySelectorAll( alvo03 )!
				
				el03.forEach( alvo03_item => {
					
					//console.log( 'alvo03_item', alvo03_item )
					
					let fonSizeFull_multi = window.getComputedStyle( alvo03_item, null ).getPropertyValue("font-size")
					let fonSizeStr_multi = fonSizeFull_multi.replace('px', '')
					let letFonSize_multi = parseFloat( fonSizeStr_multi )
					
					let lineHeightFull_multi = window.getComputedStyle( alvo03_item, null ).getPropertyValue("line-height")
					let lineHeightStr_multi = lineHeightFull_multi.replace('px', '')
					let letLineHeight_multi = parseFloat( lineHeightStr_multi )
					
					let FonSizeResult_multi = 'font-size:'+ ( letFonSize_multi - 5 ) +'px; line-height:'+ ( letLineHeight_multi - 5 ) +'px;'
					
					alvo03_item.setAttribute('style', FonSizeResult_multi)
					
				})
				
			})
			
		}
		
	}
	/*End - FONT RESIZE*/

	const contraste = (result: any) => {
		
		try{
			
			document.body.classList.toggle("tema_escuro")
			
		}
		catch(error){
			console.log('components/TooBar - contraste - Erro: ', error)
		}
		
	}

	if ( mobile ) {
		
		return (
		
			<div className="ToolBarMobile">
			
				<div className="ToolBarMobile-container">
				
					<div className="ToolBar-contato-btn telefone">
						<a
							href="tel:+4733416000"
							target="_self"
						>
							<PhoneIcon />
							<span>(47) 3341-6000</span>
						</a>
					</div>
					
					{/*
					
						<div 
							className="ToolBar-acessibilidade-btn"
							onClick={ () => contraste(1) }
						><FonteDiminuir /></div>
						
						<div 
							className="ToolBar-acessibilidade-btn"
							onClick={ () => aumentarFonte() }
						><FonteAumentar /></div>
					
					*/}
					
					<a href="e/177">
						<div 
							className="ToolBar-acessibilidade-btn"
						><AccessibilidadeIcon /></div>
					</a>
					
					<div 
						className="ToolBar-acessibilidade-btn"
						onClick={ () => contraste(1) }
					><ContrasteIcon /></div>
					
					<div className="ToolBar-contato-container">

						<div className="ToolBar-contato-btn">
							<a
								href="https://www.facebook.com/PrefeituradeItajai"
								target="_blank"
							><FacebookIcon /></a>
						</div>
						
						<div className="ToolBar-contato-btn">
							<a
								href="https://www.instagram.com/prefeituradeitajai/"
								target="_blank"
							><InstagramIcon /></a>
						</div>
						
						<div className="ToolBar-contato-btn">
							<a
								href="https://www.youtube.com/MunicipiodeItajai"
								target="_blank"
							><YoutubeIcon /></a>
						</div>
						
						<div className="ToolBar-contato-btn">
							<a
								href="https://www.flickr.com/photos/secomitajai/sets/"
								target="_blank"
							><FlickrIcon /></a>
						</div>
						
					</div>
					
				</div>
				
			</div>
			
		)
		
	}

	return (
	
		<div className="ToolBar">
		
			<div className="ToolBar-container">
			
				<div className="ToolBar-botoes">
				
					<a
						href="https://servicos.itajai.sc.gov.br/servico/79-ouvidoria.html"
						target="_blank"
					>Ouvidoria</a>
					
					<a
						href="https://servicos.itajai.sc.gov.br/servico/69-acesso_a_informacao.html"
						target="_blank"
					>e-SIC</a>
					
					<a
						onClick={() => navigate('/contato')}
					>Telefones</a>
					
					<a
						href="https://intranet2.itajai.sc.gov.br/jornal-municipio/portal/"
						target="_blank"
					>Diário Oficial</a>
					
					<a
						href="https://portaltransparencia.itajai.sc.gov.br/epublica-portal/#/itajai/portal?entidade=411"
						target="_blank"
					>Transparência</a>
					
				</div>
				
				<div className="ToolBar-acessibilidade-botoes">
				
					<div
						className="ToolBar-acessibilidade-btn"
						onClick={() =>{
							setVisible(!visible)
							//console.log(visible)
						}}
					><SinaisIcon /></div>

					<div 
						className="ToolBar-acessibilidade-btn"
						onClick={ () => contraste(1) }
					><ContrasteIcon /></div>
					
					<div 
						className="ToolBar-acessibilidade-btn"
						onClick={ () => diminuirFonte() }
					><FonteDiminuir /></div>
					
					{
						
						!!esconderFonteReset &&
						
						<div 
							className="ToolBar-acessibilidade-btn"
							onClick={ () => resetFonte() }
						><FonteReset /></div>
						
					}
					
					<div 
						className="ToolBar-acessibilidade-btn"
						onClick={ () => aumentarFonte() }
					><FonteAumentar /></div>
					
					<a href="e/177">
						<div 
							className="ToolBar-acessibilidade-btn"
						><AccessibilidadeIcon /></div>
					</a>
					
				</div>
				
				<div className="ToolBar-contato-container">

					<div className="ToolBar-contato-btn telefone">
						<a
							href="tel:+4733416000"
							target="_self"
						>
							<PhoneIcon />
							<span>(47) 3341-6000</span>
						</a>
					</div>
					
					<div className="ToolBar-contato-btn">
						<a
							href="https://www.facebook.com/PrefeituradeItajai"
							target="_blank"
						><FacebookIcon /></a>
					</div>
					
					<div className="ToolBar-contato-btn">
						<a
							href="https://www.instagram.com/prefeituradeitajai/"
							target="_blank"
						><InstagramIcon /></a>
					</div>
					
					<div className="ToolBar-contato-btn">
						<a
							href="https://www.youtube.com/MunicipiodeItajai"
							target="_blank"
						><YoutubeIcon /></a>
					</div>
					
					<div className="ToolBar-contato-btn">
						<a
							href="https://www.flickr.com/photos/secomitajai/sets/"
							target="_blank"
						><FlickrIcon /></a>
					</div>
					
				</div>
				
			</div>
			
		</div>
		
	)
	
}